import classNames from 'classnames'

import IconList, { IconType } from './iconList'

type Props = {
  className?: string
  name: IconType
  size?: number
  onClick?: () => void
}

const Icon: React.FC<Props> = (props): JSX.Element => {
  const { className, name, size = 24, onClick } = props
  const IconComp = IconList[name]

  return (
    <IconComp
      className={classNames(className, onClick ? 'cursor-pointer' : '')}
      size={size}
      onClick={onClick}
    />
  )
}

export default Icon
