import classNames from 'classnames'

import LogoMiniImg from 'assets/images/logo_mini.png'

export type Props = {
  className?: string
}

const Logo: React.FC<Props> = (props): JSX.Element => {
  const { className } = props

  return (
    <img
      className={classNames('min-w-10 w-10 h-10', className)}
      src={LogoMiniImg}
      alt="Logo"
    />
  )
}

export default Logo
