import React, { Fragment, Suspense, lazy } from 'react'

import { Switch, Route } from 'react-router-dom'

import { MainLayout } from 'layout'

import { HOME_ROUTE, POOL_ROUTE } from 'settings/constants/route'
import { FixmeType } from 'types/global'

export type RouteType = {
  exact?: boolean
  path: string
  component: React.LazyExoticComponent<FixmeType>
  layout?: React.FC<FixmeType>
  routes?: RouteType[]
}

export const routes: RouteType[] = [
  {
    exact: true,
    path: HOME_ROUTE,
    layout: MainLayout,
    component: lazy(() => import('pages/Dashboard')),
  },
  {
    exact: true,
    path: POOL_ROUTE,
    layout: MainLayout,
    component: lazy(() => import('pages/PoolView')),
  },
]

export const renderRoutes = (routes: RouteType[] = []): JSX.Element => {
  return (
    <Suspense fallback={null}>
      <Switch>
        {routes.map((route, i) => {
          const Layout = route?.layout || Fragment
          const Component = route.component

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              )}
            />
          )
        })}
      </Switch>
    </Suspense>
  )
}
