import { createAsyncThunk } from '@reduxjs/toolkit'
import * as thornodeApi from 'services/thornode'

export const getPools = createAsyncThunk(
  'thornode/getPools',
  thornodeApi.getThorchainPools,
)

export const getLiquidityProviderByPool = createAsyncThunk(
  'thornode/getLiquidityProviderByPool',
  async ({ asset }: { asset: string }) => {
    const { data } = await thornodeApi.getLiquidityProviderByPool({ asset })

    return data
  },
)
