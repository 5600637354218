import React from 'react'

import classNames from 'classnames'

export type TypographyWeight = 400 | 700

export type TypographyVariant =
  | 'hero'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'body'
  | 'caption'
  | 'caption2'
  | 'caption3'

const VARIANTS = {
  hero: 'text-hero',
  h1: 'text-h1',
  h2: 'text-h2',
  h3: 'text-h3',
  h4: 'text-h4',
  h5: 'text-h5',
  body: 'text-body',
  caption: 'text-caption',
  caption2: 'text-caption-2',
  caption3: 'text-caption-3',
}

export interface TypographyProps {
  variant?: TypographyVariant
  weight?: TypographyWeight
  component?: keyof React.ReactHTML
  className?: string
  children?: React.ReactNode
}

function Typography({
  variant = 'body',
  component: Component = 'div',
  className = 'text-light-gray',
  children = [],
}: TypographyProps): JSX.Element {
  return (
    <Component
      className={classNames(VARIANTS[variant], 'font-primary', className)}
    >
      {children}
    </Component>
  )
}

export default Typography
