import { useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import _ from 'lodash'

import { Amount, Asset } from 'multichain-sdk'

import { RootState } from 'redux/store'

import * as actions from './actions'
import { actions as sliceActions } from './slice'
import { getPoolsByFilter } from './utils'

export const useThornode = () => {
  const dispatch = useDispatch()
  const appState = useSelector((state: RootState) => state.app)
  const thornodeState = useSelector((state: RootState) => state.thornode)
  const { poolFilter } = appState
  const { activePool, pools, pendingLP } = thornodeState
  const flattenPendingLPInfo = _.flattenDeep(_.values(pendingLP))
  const pendingLPInfo = flattenPendingLPInfo.map((item) => {
    const asset = Asset.fromAssetString(item.asset)

    return {
      ...item,
      asset,
    }
  })
  const sortedPendingLPInfo = pendingLPInfo.sort((a, b) => {
    if (a.last_add_height < b.last_add_height) return 1
    if (a.last_add_height > b.last_add_height) return -1
    return 0
  })
  const pendingLPArr = sortedPendingLPInfo.map((lpInfo) => ({
    ...lpInfo,
    units: Amount.fromMidgard(lpInfo.units).toSignificant(6),
    pending_rune: Amount.fromMidgard(lpInfo.pending_rune).toSignificant(6),
    pending_asset: Amount.fromMidgard(lpInfo.pending_asset).toSignificant(6),
    rune_deposit_value: Amount.fromMidgard(
      lpInfo.rune_deposit_value,
    ).toSignificant(6),
    asset_deposit_value: Amount.fromMidgard(
      lpInfo.asset_deposit_value,
    ).toSignificant(6),
  }))

  const getActiveLpInfo = useCallback(() => {
    if (pools) {
      const filteredPools = getPoolsByFilter(pools, poolFilter)
      const filteredPoolNames = filteredPools.map((item) =>
        item.asset.toString(),
      )
      const activeAsset = filteredPoolNames.includes(activePool)
        ? activePool
        : filteredPoolNames[0]

      dispatch(
        actions.getLiquidityProviderByPool({
          asset: activeAsset,
        }),
      )
    }
  }, [pools, activePool, poolFilter, dispatch])

  const getAllLpInfo = useCallback(() => {
    if (pools) {
      pools.forEach((pool) => {
        dispatch(
          actions.getLiquidityProviderByPool({
            asset: pool.asset.toString(),
          }),
        )
      })
    }
  }, [pools, dispatch])

  const setActivePool = useCallback(
    (activePool: string) => {
      dispatch(sliceActions.setActivePool(activePool))
    },
    [dispatch],
  )

  return {
    ...thornodeState,
    pendingLPArr,
    actions,
    getActiveLpInfo,
    getAllLpInfo,
    setActivePool,
  }
}
