import Icon from 'components/Icon'
import { ExternalLink } from 'components/Link'
import { RowBetween } from 'components/Row'
import Typography from 'components/Typography'
import { APP_NAME, APP_VERSION } from 'settings/constants/global'

const Footer: React.FC = (): JSX.Element => {
  return (
    <div className="w-full h-20 bg-dark border-dark-t lg:border-transparent">
      <RowBetween className="h-full px-4">
        <Typography>{`${APP_NAME} ${APP_VERSION}`}</Typography>
        <div className="flex">
          <ExternalLink href="https://discord.gg/7drEFG6STF">
            <Icon name="discord" />
          </ExternalLink>
          <ExternalLink href="https://t.me/thorchain_org">
            <Icon name="telegram" />
          </ExternalLink>
          <ExternalLink href="https://twitter.com/thorchain">
            <Icon name="twitter" />
          </ExternalLink>
          <ExternalLink href="https://medium.com/thorchain">
            <Icon name="medium" />
          </ExternalLink>
        </div>
      </RowBetween>
    </div>
  )
}

export default Footer
