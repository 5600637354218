import Footer from 'components/Footer'
import Header from 'components/Header'

import { LayoutWrapper, BackgroundWrapper } from './MainLayout.style'

type Props = {
  children: React.ReactNode
}

const MainLayout: React.FC<Props> = ({ children }) => {
  return (
    <LayoutWrapper className="flex flex-col items-start h-screen overflow-x-hidden bg-opacity-20">
      <BackgroundWrapper />
      <div className="flex flex-row justify-between w-screen flex-nowrap">
        <Header />
      </div>
      <div className="z-0 flex flex-col items-center justify-start flex-1 w-screen h-full px-8 py-4 overflow-x-hidden overflow-y-auto">
        {children}
      </div>
      <div className="flex flex-row justify-between w-screen flex-nowrap">
        <Footer />
      </div>
    </LayoutWrapper>
  )
}

export default MainLayout
