import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { Asset, Amount } from 'multichain-sdk'

import { PoolStatus } from 'redux/app/types'

import * as thornodeActions from './actions'
import { State, GetPoolsResponse, LiquidityProvider, Pool } from './types'

const initialState: State = {
  activePool: '',
  pools: [],
  availablePools: [],
  poolLoading: false,
  pendingLP: {},
  pendingLPLoading: false,
  activeReqCount: 0,
}

const slice = createSlice({
  name: 'thornode',
  initialState,
  reducers: {
    setActivePool(state, action: PayloadAction<string>) {
      const activePool = action.payload
      state.activePool = activePool
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thornodeActions.getPools.pending, (state) => {
        state.poolLoading = true
      })
      .addCase(thornodeActions.getPools.fulfilled, (state, action) => {
        const pools: Pool[] = action.payload.map((pool: GetPoolsResponse) => {
          return {
            ...pool,
            asset: Asset.fromAssetString(pool.asset),
            balance_rune: Amount.fromMidgard(pool.balance_rune).toFixed(0),
            balance_asset: Amount.fromMidgard(pool.balance_asset).toFixed(0),
            pool_units: Amount.fromMidgard(pool.pool_units).toFixed(0),
            pending_inbound_rune: Amount.fromMidgard(
              pool.pending_inbound_rune,
            ).toFixed(0),
            pending_inbound_asset: Amount.fromMidgard(
              pool.pending_inbound_asset,
            ).toFixed(0),
          }
        })

        state.poolLoading = false
        state.activePool = pools?.[0].asset.toString() || ''
        state.pools = pools
        state.availablePools = pools.filter(
          (item) => item.status === PoolStatus.Available,
        )
      })
      .addCase(thornodeActions.getPools.rejected, (state) => {
        state.poolLoading = false
      })
      .addCase(thornodeActions.getLiquidityProviderByPool.pending, (state) => {
        state.activeReqCount++
        state.pendingLPLoading = true
      })
      .addCase(
        thornodeActions.getLiquidityProviderByPool.fulfilled,
        (state, action) => {
          const {
            arg: { asset },
          } = action.meta
          const data: LiquidityProvider[] = action.payload

          state.pendingLP = {
            ...state.pendingLP,
            [asset]: data,
          }
          state.activeReqCount--
          state.pendingLPLoading = false
        },
      )
      .addCase(thornodeActions.getLiquidityProviderByPool.rejected, (state) => {
        state.activeReqCount--
        state.pendingLPLoading = false
      })
  },
})

export const { reducer, actions } = slice

export default slice
