import React, { HTMLProps } from 'react'

import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
  NavLink as ReactRouterNavLink,
  NavLinkProps as ReactRouterNavLinkProps,
} from 'react-router-dom'

import * as H from 'history'

function Link<S = H.LocationState>({
  href = '#',
  children,
  className = 'p-2 text-baseline text-light-gray hover:text-high-emphesis focus:text-high-emphesis md:p-3',
  ...rest
}: React.PropsWithoutRef<ReactRouterLinkProps<S>> &
  React.RefAttributes<HTMLAnchorElement>): JSX.Element {
  return (
    <ReactRouterLink href={href} className={className} {...rest}>
      {children}
    </ReactRouterLink>
  )
}

export function NavLink<S = H.LocationState>({
  href = '#',
  children,
  className = 'p-2 text-baseline text-light-gray hover:text-high-emphesis focus:text-high-emphesis md:p-3 font-primary font-bold',
  ...rest
}: React.PropsWithoutRef<ReactRouterNavLinkProps<S>> &
  React.RefAttributes<HTMLAnchorElement>): JSX.Element {
  return (
    <ReactRouterNavLink
      href={href}
      className={className}
      activeClassName="font-bold text-high-emphesis"
      {...rest}
    >
      {children}
    </ReactRouterNavLink>
  )
}

export function ExternalLink({
  target = '_blank',
  href,
  children,
  rel = 'noopener noreferrer',
  className = '',
  ...rest
}: Omit<HTMLProps<HTMLAnchorElement>, 'as' | 'ref' | 'onClick'> & {
  href: string
}): JSX.Element {
  return (
    <a
      target={target}
      rel={rel}
      href={href}
      className={`text-baseline text-light-theme-primary hover:text-theme-primary p-2 md:p-3 ${className}`}
      {...rest}
    >
      {children}
    </a>
  )
}

export default Link
