import { useEffect } from 'react'

import { useLocation } from 'react-router-dom'
import { useInterval } from 'react-use'

import { Disclosure } from '@headlessui/react'
import classNames from 'classnames'

import { ReactComponent as Burger } from 'assets/images/burger.svg'
import { ReactComponent as X } from 'assets/images/x.svg'
import Icon from 'components/Icon'
import { ExternalLink, NavLink } from 'components/Link'
import Logo from 'components/Logo'
import { useGlobalState } from 'redux/hooks'
import { useThornode } from 'redux/thornode/hooks'
import { HOME_ROUTE, POOL_ROUTE } from 'settings/constants/route'

const Header: React.FC = (): JSX.Element => {
  const {
    isEnabledAutoPolling,
    pollingInterval,
    refreshPage,
  } = useGlobalState()
  const { activeReqCount, poolLoading, getActiveLpInfo } = useThornode()
  const location = useLocation()
  const isAutoPolling = isEnabledAutoPolling && location.pathname === HOME_ROUTE

  useEffect(() => {
    refreshPage()
  }, [refreshPage])

  useInterval(
    () => {
      getActiveLpInfo()
    },
    isAutoPolling ? pollingInterval * 1000 : null,
  )

  return (
    <Disclosure as="nav" className="z-10 w-screen bg-black bg-opacity-40">
      {({ open }) => (
        <>
          <div className="px-4 py-1.5">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center w-full">
                <div className="p-1 rounded-full">
                  <NavLink className="flex items-center flex-shrink-0" to="/">
                    <Logo />
                  </NavLink>
                </div>
                <div className="hidden sm:block sm:ml-4">
                  <div className="flex space-x-2">
                    <NavLink
                      to={HOME_ROUTE}
                      isActive={(match) =>
                        Boolean(match) && match?.url === HOME_ROUTE
                      }
                    >
                      Liquidity
                    </NavLink>
                    <NavLink
                      to={POOL_ROUTE}
                      isActive={(match) =>
                        Boolean(match) && match?.url === POOL_ROUTE
                      }
                    >
                      Pools
                    </NavLink>
                    <ExternalLink href="https://app.thorswap.finance/pending">
                      Recover Pending
                    </ExternalLink>
                  </div>
                </div>
              </div>

              <div className="flex flex-row items-center justify-end w-full p-4 bg-black bg-opacity-20 lg:relative lg:p-0 lg:bg-transparent">
                <ExternalLink
                  className="mr-2 font-bold"
                  href="https://app.thorswap.finance"
                >
                  Use App
                </ExternalLink>
                <div
                  className={classNames(
                    'flex items-center justify-center p-2 border rounded-full cursor-pointer border-theme-primary',
                    activeReqCount > 0 || poolLoading ? 'animate-spin' : '',
                  )}
                  onClick={getActiveLpInfo}
                >
                  <Icon size={24} name="refresh" />
                </div>
              </div>
              <div className="flex -mr-2 sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-light-gray hover:text-high-emphesis focus:outline-none">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <X
                      title="Close"
                      className="block w-6 h-6"
                      aria-hidden="true"
                    />
                  ) : (
                    <Burger
                      title="Burger"
                      className="block w-6 h-6"
                      aria-hidden="true"
                    />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="flex flex-col px-4 pt-2 pb-3 space-y-1">
              <NavLink id="dashboard-nav-link" to={HOME_ROUTE}>
                Liquidity
              </NavLink>
              <NavLink
                id="buy-nav-link"
                to={POOL_ROUTE}
                isActive={(match) => Boolean(match) || match?.url === '/'}
              >
                Pools
              </NavLink>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default Header
