import { useCallback } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { RootState } from 'redux/store'
import { actions as thornodeActions } from 'redux/thornode/slice'
import { getPoolsByFilter } from 'redux/thornode/utils'

import { actions } from './slice'
import { PoolFilter, TxFilter } from './types'

export const useApp = () => {
  const dispatch = useDispatch()

  const appState = useSelector((state: RootState) => state.app)
  const thornodeState = useSelector((state: RootState) => state.thornode)
  const { activePool, pools } = thornodeState

  const setPollingInterval = useCallback(
    (pollingInterval: number) => {
      dispatch(actions.setPollingInterval(pollingInterval))
    },
    [dispatch],
  )

  const setPoolFilter = useCallback(
    (poolFilter: PoolFilter) => {
      const filteredPools = getPoolsByFilter(pools, poolFilter)
      const filteredPoolNames = filteredPools.map((item) =>
        item.asset.toString(),
      )
      if (!filteredPoolNames.includes(activePool)) {
        dispatch(thornodeActions.setActivePool(filteredPoolNames[0]))
      }
      dispatch(actions.setPoolFilter(poolFilter))
    },
    [activePool, pools, dispatch],
  )

  const setTxFilter = useCallback(
    (txFilter: TxFilter) => {
      dispatch(actions.setTxFilter(txFilter))
    },
    [dispatch],
  )

  const setTxTablePageIndex = useCallback(
    (txTablePageIndex: number) => {
      dispatch(actions.setTxTablePageIndex(txTablePageIndex))
    },
    [dispatch],
  )

  const setAutoPollingStatus = useCallback(
    (isEnabledAutoPolling: boolean) => {
      dispatch(actions.setAutoPollingStatus(isEnabledAutoPolling))
    },
    [dispatch],
  )

  return {
    ...appState,
    setPollingInterval,
    setPoolFilter,
    setTxFilter,
    setTxTablePageIndex,
    setAutoPollingStatus,
  }
}
