export enum PoolStatus {
  Available = 'Available',
  Staged = 'Staged',
}

export enum TxStatus {
  Completed = 'Completed',
  Pending = 'Pending',
}

export type PoolFilter = Record<PoolStatus, boolean>
export type TxFilter = Record<TxStatus, boolean>

export interface State {
  pollingInterval: number
  isEnabledAutoPolling: boolean
  poolFilter: PoolFilter
  txFilter: TxFilter
  txTablePageIndex: number
}
