import {
  Discord,
  MediumSquare,
  Telegram,
  Twitter,
} from '@styled-icons/boxicons-logos'
import {
  BadgeCheck,
  CaretDown,
  CaretUp,
  Check,
  ChevronLeft,
  ChevronRight,
  Clipboard,
  Code,
  Cog,
  Error,
  Filter,
  FilterAlt,
  Heart as HeartOutlined,
  HelpCircle,
  InfoCircle,
  Minus,
  Plus,
  Refresh,
  Run,
  Wallet,
  X,
} from '@styled-icons/boxicons-regular'
import {
  Binoculars,
  Cart,
  Dashboard,
  Heart as HeartFilled,
  ShareAlt,
  Show,
  User,
} from '@styled-icons/boxicons-solid'
import { StyledIcon } from '@styled-icons/styled-icon'

export type IconType =
  | 'buy'
  | 'browse'
  | 'caretDown'
  | 'caretUp'
  | 'check'
  | 'chevronLeft'
  | 'chevronRight'
  | 'clipboard'
  | 'close'
  | 'code'
  | 'dashboard'
  | 'discord'
  | 'error'
  | 'exchange'
  | 'eye'
  | 'filter'
  | 'filterAlt'
  | 'heartOutlined'
  | 'heartFilled'
  | 'help'
  | 'info'
  | 'medium'
  | 'minus'
  | 'plus'
  | 'refresh'
  | 'run'
  | 'settings'
  | 'telegram'
  | 'twitter'
  | 'user'
  | 'verify'
  | 'wallet'

const Icons: Record<IconType, StyledIcon> = {
  browse: Binoculars,
  buy: Cart,
  caretDown: CaretDown,
  caretUp: CaretUp,
  check: Check,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  clipboard: Clipboard,
  close: X,
  code: Code,
  dashboard: Dashboard,
  discord: Discord,
  error: Error,
  exchange: ShareAlt,
  eye: Show,
  filter: Filter,
  filterAlt: FilterAlt,
  heartFilled: HeartFilled,
  heartOutlined: HeartOutlined,
  help: HelpCircle,
  info: InfoCircle,
  medium: MediumSquare,
  minus: Minus,
  plus: Plus,
  refresh: Refresh,
  run: Run,
  settings: Cog,
  telegram: Telegram,
  twitter: Twitter,
  user: User,
  verify: BadgeCheck,
  wallet: Wallet,
}

export default Icons
