import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import {
  getPollingInterval,
  getEnableAutoPolling,
  setPollingInterval,
  setEnableAutoPolling,
} from 'helpers/storage'

import { State, PoolFilter, PoolStatus, TxFilter, TxStatus } from './types'

const initialState: State = {
  pollingInterval: getPollingInterval(),
  isEnabledAutoPolling: getEnableAutoPolling(),
  poolFilter: {
    [PoolStatus.Available]: true,
    [PoolStatus.Staged]: false,
  },
  txFilter: {
    [TxStatus.Completed]: false,
    [TxStatus.Pending]: true,
  },
  txTablePageIndex: 0,
}

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setPollingInterval(state, action: PayloadAction<number>) {
      const pollingInterval = action.payload
      setPollingInterval(pollingInterval)
      state.pollingInterval = pollingInterval
    },
    setPoolFilter(state, action: PayloadAction<PoolFilter>) {
      const poolFilter = action.payload
      state.poolFilter = poolFilter
    },
    setTxFilter(state, action: PayloadAction<TxFilter>) {
      const txFilter = action.payload
      state.txFilter = txFilter
    },
    setTxTablePageIndex(state, action: PayloadAction<number>) {
      const txTablePageIndex = action.payload
      state.txTablePageIndex = txTablePageIndex
    },
    setAutoPollingStatus(state, action: PayloadAction<boolean>) {
      const autoPollingStatus = action.payload
      setEnableAutoPolling(autoPollingStatus)
      state.isEnabledAutoPolling = autoPollingStatus
    },
  },
})

export const { reducer, actions } = slice

export default slice
