import styled from 'styled-components'

export const LayoutWrapper = styled.div``

export const BackgroundWrapper = styled.div`
  position: absolute;
  opacity: 0.1;
  width: 100%;
  height: 100%;
  background: url('https://i.pinimg.com/originals/39/35/32/393532260f8031c9006015d145365d77.jpg')
    no-repeat;
  background-size: cover;
  z-index: -1;
`
