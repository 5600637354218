import { DEFAULT_POLLING_INTERVAL } from 'settings/constants/global'

const POLLING_INTERVAL = 'POLLING_INTERVAL'
const ENABLE_AUTO_POLLING = 'ENABLE_AUTO_POLLING'

export const getPollingInterval = (): number => {
  const pollingInterval = localStorage.getItem(POLLING_INTERVAL)
  if (!pollingInterval) setPollingInterval(DEFAULT_POLLING_INTERVAL)

  return pollingInterval
    ? Number.parseInt(pollingInterval, 10)
    : DEFAULT_POLLING_INTERVAL
}

export const setPollingInterval = (pollingInterval: number) => {
  localStorage.setItem(POLLING_INTERVAL, pollingInterval.toString())
}

export const getEnableAutoPolling = (): boolean => {
  return localStorage.getItem(ENABLE_AUTO_POLLING) === 'true'
}

export const setEnableAutoPolling = (isEnabledAutoPolling: boolean) => {
  localStorage.setItem(ENABLE_AUTO_POLLING, isEnabledAutoPolling.toString())
}
