import { Amount } from 'multichain-sdk'

import { PoolStatus, PoolFilter, TxFilter } from 'redux/app/types'

import { LiquidityProvider, Pool } from './types'

export const isPendingLP = (data: LiquidityProvider): boolean => {
  if (Number(data.pending_asset) > 0 || Number(data.pending_rune) > 0) {
    return true
  }

  return false
}

export const filterLPWithStatus = (
  data: LiquidityProvider,
  txFilter: TxFilter,
) => {
  if (txFilter.Pending && txFilter.Completed) return true
  if (txFilter.Pending) return isPendingLP(data)
  if (txFilter.Completed) return !isPendingLP(data)
  return false
}

export const getMidgardAmountString = (amount: string): string => {
  return Amount.fromMidgard(amount).toSignificant(6)
}

export const getPoolsByFilter = (pools: Pool[], poolFilter: PoolFilter) => {
  return pools.filter((item) => poolFilter[item.status as PoolStatus])
}
