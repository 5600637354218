import { Provider as ReduxProvider } from 'react-redux'
import { Router } from 'react-router-dom'

import { createBrowserHistory } from 'history'

import { store as reduxStore } from 'redux/store'
import { renderRoutes, routes } from 'routes'
import ThemeProvider, { ThemedGlobalStyle } from 'theme'

const history = createBrowserHistory()

function App() {
  return (
    <ReduxProvider store={reduxStore}>
      <ThemeProvider>
        <ThemedGlobalStyle />
        <Router history={history}>{renderRoutes(routes)}</Router>
      </ThemeProvider>
    </ReduxProvider>
  )
}

export default App
