import { useCallback } from 'react'

import { useDispatch } from 'react-redux'

import { useApp } from 'redux/app/hooks'
import { useThornode } from 'redux/thornode/hooks'

export const useGlobalState = () => {
  const dispatch = useDispatch()
  const { pollingInterval, isEnabledAutoPolling } = useApp()
  const { actions } = useThornode()

  const loadInitialData = useCallback(() => {
    dispatch(actions.getPools())
  }, [dispatch, actions])

  const refreshPage = useCallback(() => {
    loadInitialData()
  }, [loadInitialData])

  return {
    pollingInterval,
    isEnabledAutoPolling,
    refreshPage,
  }
}
