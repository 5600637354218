import { combineReducers } from '@reduxjs/toolkit'

import { reducer as appReducer } from './app/slice'
import { reducer as thornodeReducer } from './thornode/slice'

const rootReducer = combineReducers({
  app: appReducer,
  thornode: thornodeReducer,
})

export default rootReducer
